<template>
  <div class="search__label" @click="onLocationIconClick">
    <svg-icon name="location-icon" width="22" height="22" />
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';

export default {
  name: 'LocationIconButton',
  computed: {
    ...mapState('search', [ 'searchQuery']),
    searchString: {
      get() {
        return this.searchQuery
      },
      set(newValue) {
        this.setSearchQuery(newValue)
      },
    },
  },
  methods: {
    ...mapMutations('geolocation', {
      setVisibleLocationModal: 'SET_VISIBLE_LOCATION_MODAL',
    }),
    onFocusHandler() {
      this.onInputFocus()
      this.$emit('onFocus')
    },
    onLocationIconClick() {
      // this.$refs.searchInput.focus();
      this.setVisibleLocationModal(true)
    },
  },
}
</script>
<style scoped>
.search__label {
  cursor: pointer;
}
</style>
