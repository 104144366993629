export const actions = {
  async getEmexGuid({ dispatch }, payload) {
    try {
      const [ suggestion ] = await dispatch('getEmexSuggestion', payload)

      return {
        cityGuid: `${suggestion?.data?.code || ''}` || null,
        regionGuid: `${suggestion?.data?.city?.code || ''}` || null,
      }
    } catch (e) {
      console.log(e);

      return {
        cityGuid: null,
        regionGuid: null,
      }
    }
  },

  getEmexSuggestion(_, { region, city, limit = 1 }) {
    const searchData = {
      conditions: {
        namestarts: `${city?.name || ''} ${city?.type || ''}`.trim(),
        country: 'KZ',
      },
    }

    if (region?.name) {
      searchData.conditions.city = `${region.name} ${region?.type || ''}`.trim()
    }

    return this.$api.emex.citySuggestion(searchData, limit)
  },
}
