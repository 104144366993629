<template>
  <div
    class="input"
    :class="[
      { 'input--float': float },
      { 'input--active': active },
      { 'input--invalid': error },
      { 'input--disabled': disabled },
      { 'input--white': styleLight },
    ]"
  >
    <label class="input__label font font_sm font_grey" :class="labelClass" :for="inputId">
      <slot />
    </label>
    <div class="input__container">
      <input
        v-if="tag === 'input'"
        v-bind="$attrs"
        :id="inputId"
        ref="input"
        class="input__input"
        :value="internal_value"
        :type="type"
        :disabled="disabled"
        :data-test="dataTest"
        :placeholder="placeholder"
        :maxlength="max"
        :style="{
          borderRadius: `${borderRadius}px`
        }"
        :required="required"
        v-on="handlers"
        @focus="focus = true"
        @blur="focus = false"
      />
      <textarea
        v-if="tag === 'textarea'"
        v-bind="$attrs"
        :id="inputId"
        ref="textarea"
        class="input__input"
        :class="{
          'input__input--textarea': tag === 'textarea',
          'input__input--textarea-disabled-resize': disabledResize,
        }"
        :rows="rows"
        :disabled="disabled"
        :data-test="dataTest"
        :placeholder="placeholder"
        :maxlength="max"
        :required="required"
        v-on="handlers"
        @focus="focus = true"
        @blur="focus = false"
      />
      <slot name="after" />
    </div>
    <p v-if="showError && error" :id="`${inputId}-alert`" class="input__message" role="alert">
      <slot name="error" :error="error">
        {{ error }}
      </slot>
    </p>
    <p v-if="showSuccess && success" :id="`${inputId}-success-alert`" class="input__message" role="alert">
      <slot name="success" :error="success">
        {{ success }}
      </slot>
    </p>
    <p v-if="showMessage && message" :id="`${inputId}-message-alert`" class="input__message" role="alert">
      <slot name="success" :error="message">
        {{ message }}
      </slot>
    </p>
  </div>
</template>

<script>
import {getRandomIntInclusive} from '~/plugins/generators';

const inputTypes = { text: 'text', number: 'number' };
const validTags = ['input', 'textarea'];

export default {
  name: 'CustomInput',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: inputTypes.text,
    },
    labelClass: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'input',
      validator(value) {
        return validTags.includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    float: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: [Number, String],
      default: 300,
    },
    max: {
      type: Number,
      default: undefined,
    },
    autoHeight: {
      type: Boolean,
      default: true,
    },
    showError: {
      type: Boolean,
      default: true,
    },
    showSuccess: {
      type: Boolean,
      default: false,
    },
    showMessage: {
      type: Boolean,
      default: false,
    },
    error: {
      type: [String, Boolean],
      default: null,
    },
    success: {
      type: [String, Boolean],
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    rows: {
      type: String,
      default: '1',
    },
    dataTest: {
      type: String,
      default: null,
    },
    styleLight: {
      type: Boolean,
      default: false,
    },
    disabledResize: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    borderRadius: {
      type: String,
      default: '4px',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: getRandomIntInclusive(1, 100000),
      internal_value: this.value,
      focus: false,
      internalMaxHeight: Number(this.maxHeight),
    };
  },
  computed: {
    inputId() {
      return `input-id-${this.id}`;
    },
    active() {
      return this.focus || this.internal_value;
    },
    handlers() {
      const keys = Object.keys(this.$listeners);
      const handlers = {};
      keys.forEach((k) => {
        handlers[k] = (e) => this.$emit(k, e);
      });
      handlers.input = (e) => {
        let { value } = e.target;
        const maxLength = Number(e.target.maxLength);
        if (!Number.isNaN(maxLength) && maxLength > -1 && e.target.value.length > maxLength) {
          e.target.value = e.target.value.slice(0, maxLength);
          return;
        }

        if (this.type === inputTypes.number) {
          value = Number(value);
          const max = e.target.max ? Number(e.target.max) : Number.POSITIVE_INFINITY;
          const min = e.target.min ? Number(e.target.min) : Number.NEGATIVE_INFINITY;
          if (value < min) this.internal_value = min;
          else if (value > max) this.internal_value = max;
          else this.internal_value = value;
        } else this.internal_value = value;

        this.$emit('input', this.internal_value);
      };
      return handlers;
    },
    inputListeners() {
      return Object.assign({}, this.$listeners, {
        input: this.input,
      });
    },
  },
  watch: {
    value(value) {
      this.internal_value = value;
      const { textarea } = this.$refs;
      if (textarea && this.autoHeight) this.adjustHeight();
    },
  },
  mounted() {
    this.adjustHeight();

    const $el = this.tag === 'input' ? this.$refs.input : this.$refs.textarea

    this.$emit('mounted', $el)
  },
  methods: {
    adjustHeight() {
      const { textarea } = this.$refs;
      if (!textarea || this.disabledResize) return;
      if (textarea.offsetHeight === this.internalMaxHeight) return;
      const offset = 5;

      if (textarea && textarea.scrollHeight < this.internalMaxHeight) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + offset + 'px';
      } else {
        textarea.style.height = this.internalMaxHeight + offset + 'px';
      }
    },
    input(e) {
      this.$emit('input', e.target.value);
    },
    setFocus() {
      if (this.tag === 'input') {
        this.$refs.input.focus();
      } else {
        this.$refs.textarea.focus();
      }
    },
  },
};
</script>
