import env from '@/.env.json';
import { suggestionTypes } from '@/plugins/enums/suggestions';
import { cookieNames } from '@/plugins/enums/cookie';
import { MAX_AGE_COOKIE_CITY } from '@/plugins/config';

export const state = () => ({
  selectedCity: null,
  cityFias: null,
  visibleLocationModal: false,
  isConfirmedCity: false,
  selectedCountry: null,
  countries: [
    { value: 'RU', label: 'Россия' },
    { value: 'BY', label: 'Беларусь' },
    { value: 'KZ', label: 'Казахстан' },
    { value: 'AZ', label: 'Азербайджан' },
    { value: 'AM', label: 'Армения' },
    { value: 'KG', label: 'Кыргызстан' },
    { value: 'MD', label: 'Молдова' },
    { value: 'TJ', label: 'Таджикистан' },
    { value: 'TR', label: 'Турция' },
    { value: 'TM', label: 'Туркменистан' },
    { value: 'UZ', label: 'Узбекистан' },
  ],
});

export const getters = {
  getSelectedCity: (state) => (state.selectedCity && state.selectedCity.name) || 'Выберите город',
  getSelectedCityType: (state) => state.selectedCity?.type ? `${state.selectedCity?.type}.` : '',
  getSelectedCityCoords: (state) => [Number(state.selectedCity?.geo_lat), Number(state.selectedCity?.geo_lon)],
  getCountriesList: (state) => {
    let active = [ env?.COUNTRY || 'KZ' ]

    if (env.ACTIVE_COUNTRIES) {
      active = env.ACTIVE_COUNTRIES.split(',')
    }

    return state.countries.filter(item => active.includes(item.value.toUpperCase()))
  },
  getSelectedCountry: (state) => {
    if (!state.selectedCountry) {
      const countryCode = env?.COUNTRY || 'KZ'
      return state.countries.find(item => item.value === countryCode)
    }

    return state.selectedCountry
  },
};

export const mutations = {
  SET_SELECTED_COUNTRY: (state, payload) => {
    state.selectedCountry = payload;
  },
  SET_SELECTED_CITY: (state, payload) => {
    state.selectedCity = payload;
  },
  SET_IS_CONFIRMED_CITY(state, payload) {
    state.isConfirmedCity = payload;
  },
  SET_CITY_FIAS(state, payload) {
    state.cityFias = payload;
  },
  SET_VISIBLE_LOCATION_MODAL(state, payload) {
    state.visibleLocationModal = payload;
  },
};

export const actions = {
  findAddress({ commit, state }, payload) {
    const data = {
      query: payload.query,
      count: payload.count,
      locations: payload.locations,
    }
    if (payload.type) {
      const fromBound = { value: payload.type };
      let toBound;

      switch (payload.type) {
        case suggestionTypes.REGION:
          toBound = { value: suggestionTypes.AREA };
          break;
        case suggestionTypes.CITY:
          toBound = { value: suggestionTypes.SETTLEMENT };
          break;
        case suggestionTypes.HOUSE:
          break;
        default:
          toBound = { value: payload.type };
      }

      if (payload.bound) {
        toBound = { value: payload.bound }
      }

      data.from_bound = fromBound;
      data.to_bound = toBound;
    }
    if (payload.locationsBoost) {
      data.locations_boost = [{
        kladr_id: state.selectedCity?.kladr_id,
      }];
    }

    return this.$api.geolocation.findAddressDadata(data);
  },
  setIsConfirmedCity({ commit }, payload) {
    commit('SET_IS_CONFIRMED_CITY', payload);
  },
  async geolocateAddress({ commit }, payload) {
    try {
      const { suggestions } = await this.$api.geolocation.geolocateAddressDadata(payload);
      return suggestions;
    } catch (error) {
      console.error(error);
      return false;
    }
  },
  async setSelectedCity({ state, commit }, payload) {
    try {
      const data = {
        name: payload.city.name,
        fias_id: payload.city.fias_id,
        region_fias_id: payload.city.region_fias_id,
      }
      if (payload.setCookie) {
        this.$cookies.set(cookieNames.GEOLOCATION, JSON.stringify(payload.city), {
          maxAge: MAX_AGE_COOKIE_CITY,
          path: '/',
        });
      }

      const { country_iso_code: code } = payload.city
      const countryCode = code || env?.COUNTRY
      const currentCountry = state.countries.find(item => item.value === countryCode)

      // Почему-то зависает при принудительной загрузке в корзине (Nuxt SSR)
      await this.$api.geolocation.setSelectedCity(data);

      commit('SET_SELECTED_CITY', payload.city);
      commit('SET_SELECTED_COUNTRY', currentCountry)
    } catch (error) {
      console.error(error);
    }
  },
  async getSelectedCityByIp({ getters, dispatch }) {
    const ip = await this.$api.geolocation.getIpUrl();
    const defaultCity = {
      name: 'Алматы город',
      type: '',
      fias_id: '289029',
      region_fias_id: '1237',
      geo_lat: null,
      geo_lon: null,
      country_iso_code: 'KZ',
    };
    try {
      if (ip) {
        const { location } = await this.$api.geolocation.getSelectedCityByIpDadata(ip);

        if (location) {
          const locationData = location.data;

          const cityData = {
            name: locationData.settlement || locationData.city,
            type: locationData.settlement_type || locationData.city_type,
            fias_id: locationData.settlement_fias_id || locationData.city_fias_id,
            geo_lat: locationData.geo_lat,
            geo_lon: locationData.geo_lon,
            kladr_id: locationData.kladr_id,
            country_iso_code: locationData.country_iso_code || getters.getSelectedCountry.value,
          }

          if (getters.getSelectedCountry.value === 'KZ') {
            const emexData = {
              region: {
                name: locationData?.region || '',
                type: locationData?.region_type_full || '',
              },
              city: {
                name: locationData?.city || '',
                type: locationData?.city_type_full || '',
              },
            }

            const { cityGuid, regionGuid } = await dispatch('emex/getEmexGuid', emexData, { root: true })

            cityData.fias_id = cityGuid
            cityData.region_fias_id = regionGuid || ''
          }

          return cityData
        }
      }
      return defaultCity;
    } catch (error) {
      console.error(error);
      return defaultCity;
    }
  },
  async setSelectedCityByCookie({ dispatch }) {
    try {
      let city = this.$cookies.get(cookieNames.GEOLOCATION);

      if (!city) {
        city = {
          region: 'Алматы город',
          name: 'Алматы город',
          type: '',
          fias_id: '289029',
          region_fias_id: '1237',
          geo_lat: null,
          geo_lon: null,
          country_iso_code: 'KZ',
        };
      }

      await dispatch('setSelectedCity', { city, setCookie: false });
    } catch (error) {
      console.error(error);
    }
  },

  async selectCity({ commit, getters, dispatch, rootState, rootGetters }, { suggestion }) {
    try {

      const cityData = {
        region: suggestion.data.city.name,
        name: suggestion.data.name,
        type: suggestion.data.typename,
        fias_id: `${suggestion.data.code}`,
        geo_lat: null,
        geo_lon: null,
        region_fias_id: `${suggestion.data.city.code}`,
        kladr_id: null,
        country_iso_code: getters.getSelectedCountry.value,
      }

      await dispatch('setSelectedCity', { city: cityData, setCookie: true });

      commit('SET_CITY_FIAS', {
        city: suggestion.data.name,
        city_guid: `${suggestion.data.code}`,
        country_code: getters.getSelectedCountry.value,
        post_index: null,
        region: suggestion.data.city.name,
        region_guid: `${suggestion.data.city.code}`,
      })

      commit('SET_IS_CONFIRMED_CITY', false);
    } catch (error) {
      console.error(error);
    }
  },
};
