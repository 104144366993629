<template>
  <div v-if="suggestions" class="location-selection">
    <div class="container--modal location-selection__container">
      <div>
        <div class="location-selection__search">
          <input
            v-model="searchString"
            class="location-selection__search-input"
            placeholder="Поиск"
          />
        </div>
      </div>
      <div class="location-selection__title">
        Выберите город
      </div>
      <ul v-if="suggestions.length" class="location-selection__list">
        <div v-if="isAddressPending" class="location-selection__list-dots">
          <LoaderDots class="cart__loader" />
        </div>
        <li v-for="suggestion in suggestions" :key="suggestion.unrestricted_value" class="location-selection__item">
          <button
            class="location-selection__button font font_m"
            :class="{
              'font_medium location-selection__button_selected': getSelectedCity === suggestion.data.name,
            }"
            type="button"
            @click="selectCityHandler(suggestion)"
          >
            {{ suggestion.unrestricted_value }}
          </button>
        </li>
      </ul>
      <p v-else class="location-selection__list--empty">
        Для вашего запроса не найдено результатов
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import * as amplitude from '@amplitude/analytics-browser';
import _debounce from 'lodash/debounce';
import env from '@/.env.json';
import './index.css';
import LoaderDots from '~/components/elements/LoaderDots';
// import CustomSelect from '~/components/elements/CustomSelect';

export default {
  name: 'LocationSelection',
  components: {LoaderDots},
  props: {
    title: {
      type: String,
      default: '',
    },
    defaultCountry: {
      type: String,
      default: env.COUNTRY,
    },
  },
  data() {
    return {
      focus: false,
      searchString: null,
      suggestions: [],
      isAddressPending: false,
    };
  },
  computed: {
    ...mapGetters({
      getAddresses: 'checkout/getAddresses',
      getCountriesList: 'geolocation/getCountriesList',
      getSelectedCountry: 'geolocation/getSelectedCountry',
      getSelectedCity: 'geolocation/getSelectedCity',
    }),
    ...mapState({
      checkoutData: 'checkout/checkoutData',
    }),
    ...mapState('geolocation', [
      'visibleLocationModal',
      'selectedCountry',
    ]),
    currentCountry: {
      get() {
        return this.selectedCountry
      },
      set(value) {
        this.setSelectedCountry(value)
      },
    },
  },
  watch: {
    searchString(value) {
      this.debounce_onCityInputChange(value || '');
    },
    selectedCountry() {
      this.onCityInputChange()
    },
  },
  beforeMount() {
    this.debounce_onCityInputChange = _debounce(this.onCityInputChange, 300);

    if (!this.suggestions.length) {
      this.onCityInputChange()
    }
  },
  methods: {
    ...mapActions({
      setSelectedCity: 'geolocation/setSelectedCity',
      setAddress: 'checkout/setAddress',
      setIsConfirmedCity: 'geolocation/setIsConfirmedCity',
      selectCity: 'geolocation/selectCity',
      getEmexSuggestion: 'emex/getEmexSuggestion',
    }),
    ...mapMutations({
      setSelectedCountry: 'geolocation/SET_SELECTED_COUNTRY',
    }),
    async onCityInputChange(query = '') {
      try {
        this.suggestions = await this.getEmexSuggestion({ city: { name: query }, limit: 10 });
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async selectCityHandler(suggestion) {
      if (this.isAddressPending) return;
      this.isAddressPending = true;

      await this.selectCity({ suggestion })

      const identify = new amplitude.Identify().set('user_city', this.getSelectedCity);
      amplitude.identify(identify);
      this.isAddressPending = false;
      this.close();
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
