<template>
  <div
    class="layout"
    :class="{
      'layout--searching': isOpenedSearch
    }"
  >
    <Header :is-show-hamburger-button="false" />
    <div class="layout__content">
      <main class="layout__main">
        <Nuxt />
      </main>
    </div>
    <Footer />
    <QuickLookModal v-if="quickLookModalState" />
    <LocationModal v-show="visibleLocationModal" />
    <ModalCompanyAdd :is-show-modal="modalAddCompany" />
    <CookiesAccept />
    <AuthModal v-show="isOpenedAuthModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import * as amplitude from '@amplitude/analytics-browser';

import Header from './components/Header';
import Footer from './components/Footer';
import LocationModal from '@/layouts/components/LocationModal';

import {Schema} from '~/Service/schema/Schema';
import {generateFileOriginalPath} from '~/plugins/helpers/file';
import login from '~/mixins/login';
import CookiesAccept from '~/components/elements/CookiesAccept.vue';
import ModalCompanyAdd from '~/components/elements/modals/Company/Add';
import accountChange from '~/mixins/accountChange';
import AuthModal from '~/components/auth/Modal';
import QuickLookModal from '~/components/catalog/quickLook';

export default {
  name: 'MainLayout',
  components: {
    QuickLookModal,
    AuthModal,
    ModalCompanyAdd,
    CookiesAccept,
    Header,
    Footer,
    LocationModal,
  },
  mixins: [login, accountChange],
  middleware: [
    // 'redirectAllToMaintenance',
  ],
  head() {
    return {
      script: [
        Schema.organization(this.logoImage),
      ],
    }
  },
  computed: {
    ...mapState('auth', ['isOpenedAuthModal']),
    ...mapState('quickLook', ['quickLookModalState']),
    ...mapState('search', ['isOpenedSearch']),
    ...mapState({logo: 'logo'}),
    ...mapState({
      isOpenedSidebar: 'isOpenedSidebar',
    }),
    ...mapState('geolocation', ['visibleLocationModal']),
    ...mapState('profile', ['modalAddCompany']),
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet' || this.$mq === 'laptop';
    },
    logoPC() {
      return this.logo?.pc
    },
    logoImage() {
      return generateFileOriginalPath(this.logoPC?.id)
    },
  },
  mounted() {
    const checkDeviceType = () => {
      const width = window.innerWidth;

      const mobileBreakpoint = 768;
      const tabletBreakpoint = 1024;

      let deviceType;

      if (width < mobileBreakpoint) {
        deviceType = 'mobile';
      } else if (width < tabletBreakpoint) {
        deviceType = 'tablet';
      } else {
        deviceType = 'desktop';
      }

      const identify = new amplitude.Identify().set('device_categories', deviceType);
      amplitude.identify(identify);
    };
    checkDeviceType();
  },

}
</script>
