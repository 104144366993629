<template>
  <div class="quick-look__gallery-wrapper">
    <div class="quick-look__gallery">
      <template v-if="images.length">
        <ProductSliderImages
          v-if="mqIsMobile"
          :images="images"
          :image-sizes-primary="imageSizesPrimary"
          :video="productVideo"
        />
        <template v-else>
          <div
            v-for="(image, idx) in pngImages"
            :key="`slider-${idx}`"
            class="quick-look__gallery-item"
            @click="openImage(idx)"
          >
            <CustomImage
              class="quick-look__gallery-img"
              :class="{ 'product-images-slider-main__image_video' : image.type === 'youtube' }"
              :image="image.url"
              :image-src="image.src"
              :alt="image.caption"
            />
            <VideoIcon v-if="image.type === 'youtube'" />
          </div>
        </template>
      </template>
      <div v-else class="quick-look__gallery-item quick-look__gallery-item_empty">
        <div class="quick-look__gallery-empty">
          <img src="@/assets/images/image-fallback.svg" class="quick-look__gallery-img" />
        </div>
      </div>
    </div>
    <CoolLightBox
      v-if="images.length"
      :items="galleryItems"
      :index="imageOpenIndex"
      @close="imageOpenIndex = null"
    ></CoolLightBox>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import ProductSliderImages from '~/components/catalog/productSliderImages';
import CustomImage from '~/components/elements/CustomImage';
import VideoIcon from '~/components/elements/VideoIcon';
import {
  generateYoutubePreview,
  generateYoutubeVideoSourcePath,
} from '~/plugins/helpers/file';

export default {
  name: 'QuickLookGallery',
  components: {
    VideoIcon,
    CustomImage,
    ProductSliderImages,
    CoolLightBox,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      imageOpenIndex: null,
    }
  },
  computed: {
    mqIsMobile() {
      return this.$mq === 'mobileXs';
    },
    imageSizesPrimary() {
      return [230, 256];
    },
    pngImages() {
      let slides = []

      slides = this.images?.map(image => {
        return {
          url: image.url,
          type: 'image',
        }
      });

      if (this.productVideo) {
        slides.push({
          src: generateYoutubePreview(this.productVideo),
          type: 'youtube',
        })
      }

      return slides
    },
    galleryItems() {
      let slides = []

      slides = this.images?.map(image => {
        return {
          url: image.url.large,
        }
      })

      if (this.productVideo) {
        slides.push({ src: generateYoutubeVideoSourcePath(this.productVideo) })
      }

      return slides
    },
    productVideo() {
      return this.product?.description?.video || ''
    },
  },
  methods: {
    openImage(index) {
      this.imageOpenIndex = index;
    },
  },
}
</script>

