<template>
  <div
    ref="inputTel"
    class="tel-input"
    :class="{ 'tel-input_error': error}"
    :style="`--tel-dropdown-height: ${(onlyCountries.length * 30) + 5}px`"
  >
    <VuePhoneNumberInput
      v-model="inputValue"
      :default-country-code="defaultCountry"
      :only-countries="onlyCountries"
      :no-example="true"
      size="lg"
      :no-validator-state="true"
      :translations="{
        countrySelectorLabel: 'Код',
        countrySelectorError: '',
        phoneNumberLabel: 'Номер телефона',
        example: ''
      }"
      :disabled="disabled"
      @update="onInputUpdate"
    />
    <div v-if="error" class="tel-input__error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'InputTel',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    mode: {
      type: String,
      default: 'international',
    },
    dropdownOptions: {
      type: Object,
      default: () => {},
    },
    inputOptions: {
      type: Object,
      default: () => {},
    },
    error: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Введите телефон',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const defaultDropdown = {
      showDialCodeInList: true,
      showDialCodeInSelection: false,
      showSearchBox: true,
      showFlags: true,
      ignoredCountries: [],
    }

    const defaultInput = {
      placeholder: this.placeholder,
      enabledCountryCode: true,
      showDialCode: true,
    }

    return {
      optionsDropdown: { ...defaultDropdown },
      defaultDropdown: { ...defaultDropdown },

      optionsInput: { ...defaultInput },
      defaultInput: { ...defaultInput },

      lastValidMask: null,
      lastValidPhone: null,

      validatedPhone: {
        isValid: false,
        international: '',
        number: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      getCountriesList: 'geolocation/getCountriesList',
      getSelectedCountry: 'geolocation/getSelectedCountry',
    }),
    inputValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue || '')
      },
    },
    onlyCountries() {
      return this.getCountriesList.map(item => item.value)
    },
    defaultCountry() {
      return this.getSelectedCountry.value
    },
  },
  watch: {
    placeholder() {
      this.optionsInput.placeholder = this.placeholder
    },
    inputValue() {
      if (!this.lastValidMask || !this.lastValidPhone) {
        this.$emit('onValidate', { ...this.validatedPhone })
        return
      }

      if (this.validatedPhone.number.length >= this.lastValidPhone.length) {
        this.inputValue = this.lastValidMask
        return;
      }

      if (this.validatedPhone.number.length < this.lastValidMask.length) {
        this.lastValidMask = null
        this.lastValidPhone = null
      }

      this.$emit('onValidate', { ...this.validatedPhone })
    },
  },
  methods: {
    onInputUpdate({ isValid, formatInternational, formattedNumber }) {
      this.validatedPhone = { isValid, international: formatInternational, number: formattedNumber }

      if (isValid) {
        this.$nextTick(() => {
          this.lastValidMask = this.inputValue
          this.lastValidPhone = formattedNumber
        })
      } else if (this.lastValidPhone) {
        if (!formattedNumber || this.lastValidPhone[2] !== formattedNumber[2]) {
          this.lastValidMask = null
          this.lastValidPhone = null
        }
      }
    },
  },
}
</script>
