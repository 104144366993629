var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input",class:[
    { 'input--float': _vm.float },
    { 'input--active': _vm.active },
    { 'input--invalid': _vm.error },
    { 'input--disabled': _vm.disabled },
    { 'input--white': _vm.styleLight },
  ]},[_c('label',{staticClass:"input__label font font_sm font_grey",class:_vm.labelClass,attrs:{"for":_vm.inputId}},[_vm._t("default")],2),_vm._v(" "),_c('div',{staticClass:"input__container"},[(_vm.tag === 'input')?_c('input',_vm._g(_vm._b({ref:"input",staticClass:"input__input",style:({
        borderRadius: `${_vm.borderRadius}px`
      }),attrs:{"id":_vm.inputId,"type":_vm.type,"disabled":_vm.disabled,"data-test":_vm.dataTest,"placeholder":_vm.placeholder,"maxlength":_vm.max,"required":_vm.required},domProps:{"value":_vm.internal_value},on:{"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false}}},'input',_vm.$attrs,false),_vm.handlers)):_vm._e(),_vm._v(" "),(_vm.tag === 'textarea')?_c('textarea',_vm._g(_vm._b({ref:"textarea",staticClass:"input__input",class:{
        'input__input--textarea': _vm.tag === 'textarea',
        'input__input--textarea-disabled-resize': _vm.disabledResize,
      },attrs:{"id":_vm.inputId,"rows":_vm.rows,"disabled":_vm.disabled,"data-test":_vm.dataTest,"placeholder":_vm.placeholder,"maxlength":_vm.max,"required":_vm.required},on:{"focus":function($event){_vm.focus = true},"blur":function($event){_vm.focus = false}}},'textarea',_vm.$attrs,false),_vm.handlers)):_vm._e(),_vm._v(" "),_vm._t("after")],2),_vm._v(" "),(_vm.showError && _vm.error)?_c('p',{staticClass:"input__message",attrs:{"id":`${_vm.inputId}-alert`,"role":"alert"}},[_vm._t("error",function(){return [_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]},{"error":_vm.error})],2):_vm._e(),_vm._v(" "),(_vm.showSuccess && _vm.success)?_c('p',{staticClass:"input__message",attrs:{"id":`${_vm.inputId}-success-alert`,"role":"alert"}},[_vm._t("success",function(){return [_vm._v("\n      "+_vm._s(_vm.success)+"\n    ")]},{"error":_vm.success})],2):_vm._e(),_vm._v(" "),(_vm.showMessage && _vm.message)?_c('p',{staticClass:"input__message",attrs:{"id":`${_vm.inputId}-message-alert`,"role":"alert"}},[_vm._t("success",function(){return [_vm._v("\n      "+_vm._s(_vm.message)+"\n    ")]},{"error":_vm.message})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }