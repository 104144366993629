<template>
  <div class="search-products__item" @click="$emit('click')">
    <div class="search-products__img">
      <CustomImage :image="getImage(item)" :alt="productName(item)" size="small" retina="medium" />
    </div>
    <div>{{ productName(item) }}</div>
  </div>
</template>

<script>
import CustomImage from '~/components/elements/CustomImage';

export default {
  name: 'SearchResultProductsItem',
  components: {CustomImage},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageSizes: [46, 46],
    }
  },
  methods: {
    getImage(product) {
      if (product.image) {
        return product.image.url
      } else if (Array.isArray(product.media) && product.media.length > 0) {
        return product.media[0].url
      }

      return '';
    },
    productName(product) {
      return product.name ?? product.title;
    },
  },
}
</script>

