export const cookieNames = Object.freeze({
  REFERRAL: 'referral',
  GEOLOCATION: 'user_geo',
  LAST_PAGE: 'lastPage',
  AGREE_COOKIE: 'agreeCookie',
  API_SESSION: 'apims_session',
});

export const interval = Object.freeze({
  SECOND: 1000,
  TWO_SECONDS: 2 * 1000,
  MINUTE: 60 * 1000,
  FIVE_MINUTES: 5 * 60 * 1000,
  TEN_MINUTES: 10 * 60 * 1000,
  HALF_HOUR: 30 * 60 * 1000,
  HOUR: 60 * 60 * 1000,
  DAY: 24 * 60 * 60 * 1000,
  WEEK: 7 * 24 * 60 * 60 * 1000,
  MONTH: 30 * 24 * 60 * 60 * 1000,
});
